import './footer.scss';

export default function Footer() {
    return (
        <footer>
            <div className="div-footer">
                <span className="span-flex-1 span-made-by">Made with ❤️ by Pierre R.</span>
                <span className="span-flex-1">
                    <a href="https://fiskagroup.com" rel="noreferrer" target="_blank">Fiska\Group</a>
             © {new Date().getFullYear()}. Tous les droits sont réservés.
          </span>
                <span className="span-flex-1"></span>
            </div>
        </footer>
    )
}