import './header.scss';

export default function Header() {
    return (
        <header>
            <img src="https://fiskagroup.com/assets/logo/FiskaGroup.png" alt="Logo Fiska\Group" className="logo-fiskagroup" />
            <div className="header-triangle-code" />
            <h1 className="title-header">Convertisseur de fichier Excel</h1>
        </header >
    )
}