import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Auth } from '../../services/auth.service';

import './login.scss';
import * as _ from 'lodash';

export default function Login({ setToken }) {
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [error, setError] = useState();

    const handleSubmit = async e => {
        e.preventDefault();
        const token = Auth.loginUser({ username, password });

        if (_.isObject(token)) {
            setToken(token);

        } else {
            setError(token);
        }
    }
    return (
        <div className="login-wrapper">
            <div className="login-container">
                <img src="https://fiskagroup.com/assets/logo/FiskaGroup.png" alt="Logo Fiska\Group" className="logo-fiskagroup" />
                <span className="title-login">Se connecter</span>
                <span className="description-login">Entrez votre identifiant et mot de passe pour vous connecter.</span>
                <form onSubmit={handleSubmit}>
                    <div className="div-input-login">
                        <label className="label-input-login">Identifiant :</label>
                        <input className="input-login" type="text" onChange={e => setUserName(e.target.value)} />
                    </div>
                    <div className="div-input-login">
                        <label className="label-input-login">Mot de passe :</label>
                        <input className="input-login" type="password" onChange={e => setPassword(e.target.value)} />
                    </div>
                    {error &&
                        <span className="span-error-login">{error}</span>
                    }
                    <div className="div-button-login">
                        <button className="btn-login" type="submit">Se connecter</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
};