import React from 'react';

import './converter.scss';

import { Api, LINK_API_URL } from '../../services/api.service';

import { render } from 'react-dom';
import Moment from 'moment';


// import locale file
import 'moment/locale/fr';

import logo from '../../assets/logo.png';

import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import xlsx from 'xlsx';
import { CSVLink } from "react-csv";
import { getJsDateFromExcel } from "excel-date-to-js";

//Popup d'alerte
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { w3cwebsocket as W3CWebSocket } from "websocket";

import lodash from 'lodash';
import download from 'downloadjs';

const listOfExcelFields = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "AA", "AB", "AC", "AD", "AE", "AF", "AG", "AH", "AI", "AJ", "AK", "AL", "AM", "AN", "AO", "AP", "AQ", "AR", "AS", "AT", "AU", "AV", "AW", "AX", "AY", "AZ", "BA", "BB", "BC", "BD", "BE", "BF", "BG", "BH", "BI", "BJ", "BK", "BL", "BM", "BN", "BO", "BP", "BQ", "BR", "BS", "BT", "BU", "BV", "BW", "BX", "BY", "BZ"];
const listOfFieldsExpected = ['Receipt No', 'Receipt Date (EUT)', 'IRNo', 'Prod Name', 'Product Price', 'Ship Fee', 'VAT', 'Total Paid Amount', 'Currency', 'VAT %', 'IRName', 'Addr1', 'City', 'Zip Code', 'Country Name'];
var listOfFieldsReceived = [];


const listOfAddresses = [{
    countryCode: 'FR',
    society: 'France SASU',
    address: '68 rue principale',
    address2: '57800 Betting'
}, {
    countryCode: 'DE',
    society: 'Deutschland GmbH',
    address: 'Neuhofstr. 9',
    address2: '64625 Bensheim'
}]

const client = new W3CWebSocket('wss://' + LINK_API_URL + '/wss');

const Progress = ({ progress }) => {
    return (
        <div className="progress">
            <div className="progress-done" style={{ width: `${progress}%` }}>
                {progress}%
            </div>
        </div>
    )
}

class Converter extends React.Component {
    constructor() {
        super();
        this.state = {
            file: null,
            countryCode: null,
            billingNumber: 0,

            //Loading
            uploadingData: false,
            generatingExportComptable: false,
            generatingExportDATEV: false,
            downloadingInvoice: false,

            //Data
            datasFromFile: [],
            exportComptableData: [],
            datevData: [],
            invoicesData: [],

            numberOfPdfGenerated: '',
            percentOfPdfGenerated: 0,
            progress: 0,

            //Error
            error: null,
        };
        this.fileToUpload = null;
    }

    componentWillMount() {
        client.onopen = () => {
            console.log('WebSocket Client Connected');
        };
        client.onmessage = (message) => {
            const data = JSON.parse(message.data);
            this.setState(data);
        };
    }

    handleFileInput = (files) => {
        const file = files.target.files;
        const fileArr = Array.prototype.slice.call(file);
        this.setState({ file: fileArr[0], uploadingData: true });

        this.fileToUpload = files.target.files.item(0);
        this.process();
    }

    removeFile = (f) => {
        confirmAlert({
            title: 'Confirmation de suppression',
            message: 'Êtes-vous sûr de vouloir retirer ce fichier ?',
            buttons: [
                {
                    label: 'Supprimer',
                    onClick: () => {
                        // Réinitialiser toutes les valeurs
                        this.setState({
                            file: null,
                            datasFromFile: [],
                            exportComptableData: [],
                            datevData: [],
                            invoicesData: [],
                            error: null
                        });
                        listOfFieldsReceived = [];
                        this.fileToUpload = null;
                    }
                },
                {
                    label: 'Annuler'
                }
            ]
        });
    }

    chooseCountry(countryCode) {
        this.setState({ countryCode: countryCode })
    }

    getObjectSize(obj) {
        var size = 0,
            key;
        for (key in obj) {
            if (obj.hasOwnProperty(key)) size++;
        }
        return size;
    };

    process = () => {
        let fileReader = new FileReader();
        fileReader.onload = (e) => {
            this.arrayBuffer = fileReader.result;
            var data = new Uint8Array(this.arrayBuffer);
            var arr = [];
            for (var i = 0; i !== data.length; ++i) arr[i] = String.fromCharCode(data[i]);
            var bstr = arr.join("");
            var workbook = xlsx.read(bstr, { type: "binary" });
            var first_sheet_name = workbook.SheetNames[0];
            var worksheet = workbook.Sheets[first_sheet_name];

            // Process data of file uploaded
            this.processExcelFields(worksheet);

            if (this.state.error === null) {
                // Generate data
                this.setState({ datasFromFile: [] });

                let datasFromFile = [];
                xlsx.utils.sheet_to_json(worksheet).forEach((element) => {
                    datasFromFile.push(element);
                });

                this.setState({ datasFromFile: datasFromFile, generatingExportComptable: true, generatingExportDATEV: true });

                // Generate data for export
                this.generateExportComptable();
                this.generateExportDATEV();
                this.setState({ uploadingData: false });

            } else {
                this.setState({ uploadingData: false });
            };
        }

        if (this.fileToUpload) {
            fileReader.readAsArrayBuffer(this.fileToUpload);
        }
    }


    processExcelFields = (worksheet) => {
        // Reset values
        listOfFieldsReceived = [];
        this.setState({ error: null });


        // Prepare array with objects
        listOfFieldsExpected.forEach(field => {
            listOfFieldsReceived.push({ label: field, present: false });
        });

        // Check all fields of the file
        let numberOfUndefined = 0;
        for (let index = 0; index < listOfExcelFields.length; index++) {
            const value = lodash.get(worksheet[listOfExcelFields[index] + '1'], 'w', undefined);

            if (!lodash.isUndefined(value)) {
                const indexField = listOfFieldsReceived.findIndex(f => f.label === value);

                if (~indexField) {
                    listOfFieldsReceived[indexField] = { label: value, present: true }
                }
            } else {
                numberOfUndefined++;

                if (numberOfUndefined > 2) {
                    break;
                }
            }
        }

        // Check if a field is missing
        for (let index = 0; index < listOfFieldsReceived.length; index++) {
            const element = listOfFieldsReceived[index];

            if (!element.present) {
                this.setState({ error: element.label + " est absent du fichier." });
                break;
            }
        };
    }

    generateExportComptable = () => {
        let table = [];

        for (let i = 0; i < this.state.datasFromFile.length; i++) {
            const data = this.state.datasFromFile[i];

            if (this.getObjectSize(data) > 15) {

                table.push(this.createTrObjExportComptable(data, table.length, -data[('Total Paid Amount')], "01"));
                table.push(this.createTrObjExportComptable(data, table.length, data[('Ship Fee')], "70851"));

                const tauxTVA = data[('VAT %')];
                if (tauxTVA === 20) {
                    table.push(this.createTrObjExportComptable(data, table.length, data[('VAT')], "44571000"));

                } else if (tauxTVA === 10) {
                    table.push(this.createTrObjExportComptable(data, table.length, data[('VAT')], "44571100"));

                } else {
                    // 445712 = TVA 5.5%
                    table.push(this.createTrObjExportComptable(data, table.length, data[('VAT')], "44571200"));
                }

                if (tauxTVA === 20) {
                    table.push(this.createTrObjExportComptable(data, table.length, data[('Product Price')], "707100"));

                } else {
                    table.push(this.createTrObjExportComptable(data, table.length, data[('Product Price')], "707200"));
                }
            }
        }

        this.setState({ exportComptableData: table, generatingExportComptable: false });
    }

    createTrObjExportComptable = (data, lineNumber, total, bookkeepingAccount) => ({
        "Line": lineNumber + 1,
        "Journal": "VE",
        "Date": (Moment(getJsDateFromExcel(data[('Receipt Date (EUT)')])).format('DD/MM/YYYY')).toString(),
        "Bookkeeping account": bookkeepingAccount,
        "Customer Full Name": data[('IRName')],
        "Document Nr": data[('Receipt No')],
        "Debit": total < 0 ? (total * -1) : "",
        "Credit": total < 0 ? "" : total,
        "Country Code": "FR"
    });

    generateExportDATEV = () => {
        let table = [];
        let lastOrderNo = "";
        let billingNumber = this.state.billingNumber;

        for (let i = 0; i < this.state.datasFromFile.length; i++) {
            const data = this.state.datasFromFile[i];

            if (this.getObjectSize(data) > 15 && data[('Total Paid Amount')] > 0) {
                const tauxTVA = data[('VAT %')];

                let numberAccount;

                switch (tauxTVA) {
                    case 20:
                        numberAccount = '4321';
                        break;

                    case 19:
                        numberAccount = '4400';
                        break;

                    case 10:
                        numberAccount = '4320';
                        break;

                    case 7:
                        numberAccount = '4300';
                        break;

                    case 0:
                        if ((data[('Country Name')]).toLowerCase().includes('switzerland')) {
                            numberAccount = '4120';
                        } else {
                            numberAccount = '4125';
                        }

                        break;

                    default:
                        console.log(data[('Total Paid Amount')]);

                        numberAccount = "???"
                        break;
                }

                if (!lastOrderNo) {
                    lastOrderNo = data['Order No'];

                } else if (data['Order No'] !== lastOrderNo) {
                    billingNumber++;
                    lastOrderNo = data['Order No'];
                }

                if (numberAccount !== "???") {
                    table.push(this.createTrObjDATEV(data, numberAccount, billingNumber));
                }
            }
        }

        this.setState({ datevData: table, generatingExportDATEV: false });
    }

    createTrObjDATEV = (data, numberAccount, billingNumber) => (
        `${(Moment(getJsDateFromExcel(data[('Receipt Date (EUT)')])).format('DD.MM.YYYY')).toString()};${data['Total Paid Amount']};${numberAccount};${billingNumber};${data['IRNo']};0`
    );

    generateDataInvoice = () => {
        this.setState({ uploadingData: true });

        let table = [];
        let lastOrderNo = "";
        let billingNumber = this.state.billingNumber;

        for (let i = 0; i < this.state.datasFromFile.length; i++) {
            const data = this.state.datasFromFile[i];

            if (this.getObjectSize(data) > 15) {

                console.log(data['Order No']);

                // Vérifier si il y a déjà une facture avec le même numéro de facture
                const index = table.findIndex(d => d.orderNo === data['Order No']);

                if (~index) {
                    table[index].product.push(this.createObjProductForInvoice(data))

                } else {
                    if (!lastOrderNo) {
                        lastOrderNo = data['Order No'];

                    } else if (data['Order No'] !== lastOrderNo) {
                        billingNumber++;
                        lastOrderNo = data['Order No'];
                    }

                    let billingNumberForInvoice = this.state.countryCode === 'DE' ? billingNumber : null;

                    console.log(billingNumberForInvoice);


                    table.push(this.createTrObjGenerateInvoice(data, billingNumberForInvoice));
                }
            }
        }

        this.setState({ invoicesData: table, uploadingData: false }, async () => {
            this.setState({ downloadingInvoice: true });

            client.send(JSON.stringify({ type: "generateInvoice" }));
            await Api.apiGenerateInvoice(this.state.invoicesData, this.state.countryCode);

            this.setState({ downloadingInvoice: false });
        });
    }

    createTrObjGenerateInvoice = (data, billingNumber = null) => ({
        "billingNumber": billingNumber ? `RC-2022-${billingNumber}` : data[('Receipt No')],
        "clientID": data[('IRNo')],
        "orderNo": data[('Order No')],
        "date": (Moment(getJsDateFromExcel(data[('Receipt Date (EUT)')])).format('MM/DD/YYYY')).toString(),
        "product": [this.createObjProductForInvoice(data)],
        "invoiceName": data[('IRName')],
        "invoiceAddress": data[('Addr1')],
        "invoiceCity": data[('City')],
        "invoiceZipCode": data[('Zip Code')],
        "invoiceCountry": data[('Country Name')]
    });

    createObjProductForInvoice = (data) => ({
        "prodName": data[('Prod Name')],
        "productPriceHT": data[('Product Price')],
        "amountTVA": data[('VAT')],
        "percentTVA": data[('VAT %')],
        "shipFee": data[('Ship Fee')],
        "total": data[('Total Paid Amount')],
        "currency": data[('Currency')]
    });

    generateInvoice = async () => {
        this.generateDataInvoice();
    }

    downloadExportDATEV = () => {
        let output = [];

        output.push("Date facture;Montant TTC;Compte;Numéro facture;Numéro Client;0");
        output.push(...this.state.datevData);
        output = output.join("\n");

        download(output, "Export_DATEV_" + Moment(new Date()).format('DD/MM/YYYY') + ".csv");
    }

    handleInputChangeBillingNumber(e) {
        this.setState({ billingNumber: lodash.get(e, 'target.value', 0) });
    }

    render = () => {
        return (
            <div className="container-converter">
                <section>
                    <div className="div-container-upload-file">
                        <span className="title-span-upload-file">Insérer le fichier :</span>
                        <div className="div-description-upload-file">
                            <span className="span-description-upload-file">Afin de générer les factures et un export comptable, veuillez joindre, en cliquant sur le bouton associé, un fichier Excel contenant les champs :</span>
                            <span className="span-description-upload-file italic">'Receipt No', 'Receipt Date (EUT)', 'IRNo', 'Prod Name', 'Product Price', 'Ship Fee', 'VAT', 'Total Paid Amount', 'Currency', 'VAT %', 'IRName', 'Addr1', 'City', 'Zip Code', 'Country Name'.</span>
                            <span className="span-description-upload-file">Si un champ est manquant une erreur ressort.</span>
                        </div>
                        <label className="custom-file-upload">
                            <input type="file"
                                onChange={(e) => { this.handleFileInput(e) }}
                                onClick={(e) => { e.target.value = null }} />
                            <FontAwesomeIcon icon={faCloudUploadAlt} /> Joindre
                        </label>

                        {this.state.file &&
                            <div className="div-file-upload-infos">
                                <span className="title-span-upload-file">Fichier actuellement téléchargé :</span>
                                <div className="file-preview">
                                    <span className="file-name" onClick={this.removeFile.bind()}>
                                        {this.state.file.name}
                                        <FontAwesomeIcon className="icon-trash" icon={faTrash} />
                                    </span>
                                </div>

                                {!this.state.error &&
                                    <div className="div-file-infos">
                                        <span className="span-file-infos">Infos du fichier : </span>
                                        <span>{this.state.datasFromFile.length} lignes</span>
                                    </div>}

                                {this.state.error &&
                                    <div className="div-file-error">
                                        <span className="span-file-error">Erreur sur le fichier : </span>
                                        <span>{this.state.error}</span>
                                    </div>}
                            </div>
                        }

                        {this.state.file && this.state.error === null &&
                            <div className="container-select-address">
                                <span className="title-span-upload-file">Sélectionner une adresse :</span>
                                <span className="span-description-select-address">Merci de séléctionner une adresse dans le but d'afficher ces données sur les factures et générer l'export comptable associé. </span>
                                <div className="container-choose-address">
                                    {listOfAddresses.map(value => {
                                        return <div className={`div-choose-address ${this.state.countryCode === value.countryCode ? 'div-choose-address-selected' : ''}`} onClick={this.chooseCountry.bind(this, value.countryCode)}>
                                            <span>{value.society}</span>
                                            <span>{value.address}</span>
                                            <span>{value.address2}</span>
                                        </div>
                                    })}
                                </div>
                            </div>
                        }

                        {this.state.file && this.state.error === null && this.state.countryCode === 'DE' &&
                            <div className="container-select-address">
                                <span className="title-span-upload-file">Renseigner un numéro de facture :</span>
                                <span className="span-description-select-address">Merci de renseigner un numéro de facture par lequel les factures vont commencées. </span>

                                <div className="container-choose-address">
                                    <span>RC-2022-</span>
                                    <input type="number" value={this.state.billingNumber} onChange={this.handleInputChangeBillingNumber.bind(this)}></input>
                                </div>
                            </div>
                        }
                    </div>

                    <div className="div-generate-data">
                        {this.state.exportComptableData.length > 0 && this.state.error === null && this.state.countryCode === 'FR' &&
                            <CSVLink data={this.state.exportComptableData} separator=";" filename={"Export_comptable_" + Moment(new Date()).format('DD/MM/YYYY') + ".csv"}>
                                <button className="btn-download">
                                    Télécharger l'export comptable
                                </button>
                            </CSVLink>
                        }

                        {this.state.datevData.length > 0 && this.state.error === null && this.state.countryCode === 'DE' && this.state.billingNumber !== 0 &&
                            // <CSVLink data={this.state.datevData} separator=";" filename={"Export_DATEV_" + Moment(new Date()).format('DD/MM/YYYY') + ".csv"}>
                            //     <button className="btn-download">
                            //         Télécharger l'export DATEV
                            //     </button>
                            // </CSVLink>

                            <button onClick={this.downloadExportDATEV} className="btn-download"> Télécharger l'export DATEV </button>
                        }

                        {this.state.file && this.state.error === null && (this.state.countryCode === 'FR' || (this.state.countryCode === 'DE' && this.state.billingNumber !== 0)) &&
                            <button className="btn-download" onClick={this.generateInvoice}>
                                Générer les factures
                            </button>
                        }
                    </div>

                    <div className="div-loading">
                        {/* LOADING */}
                        {this.state.uploadingData &&
                            <div>
                                <span>Traitement du fichier Excel</span>
                            </div>
                        }

                        {this.state.generatingExportComptable &&
                            <div>
                                <span>Génération de l'export comptable en cours</span>
                            </div>
                        }

                        {this.state.generatingExportDATEV &&
                            <div>
                                <span>Génération de l'export DATEV en cours</span>
                            </div>
                        }

                        {this.state.downloadingInvoice &&
                            <div className="wrapper">
                                <div className="div-container-loading">
                                    <span className="span-loading typing-demo">Génération et téléchargement des factures en cours
                                        <span className="dot"></span>
                                        <span className="dot"></span>
                                        <span className="dot"></span>
                                    </span>
                                </div>
                            </div>
                        }

                        {(this.state.uploadingData || this.state.generatingExportComptable || this.state.downloadingInvoice || this.state.generatingExportDATEV) &&
                            <div className="div-container-loading-spinner">
                                <img src={logo} className="app-logo" alt="logo" />

                                <div className="spinner-box">
                                    <div className="circle-border">
                                        <div className="circle-core">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        {this.state.downloadingInvoice &&
                            <div className="div-container-number-invoices-generated">
                                {this.state.numberOfPdfGenerated} ({this.state.percentOfPdfGenerated}%).
                                <Progress progress={this.state.percentOfPdfGenerated} />
                            </div>
                        }
                        {/* FIN LOADING  */}
                    </div>
                </section>
            </div>
        );
    }
}

render(<Converter />, document.getElementById('root'));
export default Converter;
