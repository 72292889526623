// import { forkJoin } from 'rxjs';
import { saveAs } from 'file-saver';
import axios from 'axios';
import JSZip from 'jszip';
const zip = new JSZip();

// import * as _ from 'lodash';


/***************************  URL ******************/
export const API_URL = process.env.REACT_APP_API_URL + "api/public/fiskagroup";
export const LINK_API_URL = process.env.REACT_APP_LINK_API_URL;
//export const IP_ADDRESS = process.env.REACT_APP_IP_ADDRESS;

// const imgurl = `${environment.imgUrl}uploads/`;


export const Api = {
    apiGenerateInvoice: async function (datasFromFile, countryCode) {
        try {
            await axios.post(API_URL + '/generate-invoice', { datasFromFile, countryCode }).then(async res => {
                const nameFolder = res.data.data;
                let filesList = [];

                for (const element of datasFromFile) {
                    const namePDF = (`${element.clientID}_${element.billingNumber}`).replace(/ /g, "_");
                    await axios.get(API_URL + '/download-invoice', {
                        params: { folderPath: nameFolder, namePDF: namePDF },
                        responseType: 'blob',
                        timeout: 30000,
                    }).then(file => {
                        filesList.push(file);
                    });
                };

                filesList.forEach((element, index) => {
                    const namePDF = (`${datasFromFile[index].clientID}_${datasFromFile[index].billingNumber}`).replace(/ /g, "_");
                    zip.file(namePDF + ".pdf", element.data);
                });

                const day = new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate();
                const month = new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1);
                const year = new Date().getFullYear();

                let name = `Invoices_QN_Europe_${day}_${month}_${year}_${countryCode}.zip`
                zip.generateAsync({ type: "blob" }).then(function (content) {
                    saveAs(content, name);
                });
            })

        } catch (error) {
            console.log(error)
        }
    }
}

export default Api;