import React from 'react';

import './App.scss';

import { Auth } from './services/auth.service';

import Header from './components/header/header';
import Footer from './components/footer/footer';

import Login from './components/login/login';
import Converter from './components/converter/converter';

function App() {
  const { token, setToken } = Auth.useToken();

  if (!token) {
    return (
      <div>
        <Login setToken={setToken} />
        <Footer />
      </div>
    );
  }

  return (
    <div>
      <Header />
      <Converter />
      <Footer />
    </div>
  );
}

export default App;